<template>
    <div class="daily">
        <div class="header">
            <div class="select-date" @click="openPicker()">
                <div class="date" v-text="srchDate"></div>
                <div class="drop-down-btn"></div>
            </div>
            <mt-datetime-picker
                    ref="picker"
                    v-model="pickerVisible"
                    type="date"
                    year-format="{value} 年"
                    month-format="{value} 月"
                    date-format="{value} 日"
                    @confirm="handleConfirm()"
                    :endDate="getYesterday"
            ></mt-datetime-picker>
        </div>
        <section>
            <div class="titBox">
                <p>当日销量</p>
                <span class="tips" v-text="'数据截至' + srchDate"></span>
            </div>
            <div class="dataBox">
                <div class="dataBox_0">
                    <div id="myChart_0" class="left" :style="{width: '15rem', height: '15rem'}"></div>
                    <div class="chart_desc left">
                        <div>
                            <i class="addr0"></i>
                            <span class="name_city">广东</span>
                            <span class="num_city" v-text="numFormat(gdSale) +'元'"></span>
                        </div>
                        <div>
                            <i class="addr1"></i>
                            <span class="name_city">北京</span>
                            <span class="num_city" v-text="numFormat(bjSale) +'元'"></span>
                        </div>
                        <div>
                            <i class="addr2"></i>
                            <span class="name_city">天津</span>
                            <span class="num_city" v-text="numFormat(tjSale) +'元'"></span>
                        </div>
                        <div>
                            <i class="addr3"></i>
                            <span class="name_city">全国</span>
                            <span class="num_city" v-text="numFormat(qgSale) +'元'"></span>
                        </div>
                    </div>
                </div>
                <div class="dataBox_1 clear">
                    <div class="data_big">
                        <p>本月北单全国销量</p>
                        <p v-text="numFormat(monthSale) + '元'"></p>
                    </div>
                    <div class="data_big">
                        <p>本年度北单全国销量</p>
                        <p v-text="numFormat(yearSale) + '元'"></p>
                    </div>
                </div>
            </div>
        </section>

        <section v-show="saleFlag">
            <div class="titBox">
                <p>竞猜场次</p>
                <span class="tips">不包含胜负过关玩法</span>
            </div>
            <div class="dataBox">
                <div class="dataBox_5">
                    <div v-show="timeArr2.length == 0" v-text="hint2 + 'sasajsah'" class="chartTips"></div>
                    <div v-show="timeArr2.length > 0" id="myChart_3" class="left"
                         :style="{width: '37.5rem', height: '25rem'}"></div>
                </div>
            </div>
        </section>
        <section>
            <div class="titBox">
                <p>销量走势</p>
            </div>
            <div class="dataBox">
                <div class="dataBox_2">
                    <div v-show="timeArr.length == 0" v-text="hint" class="chartTips"></div>
                    <div v-show="timeArr.length > 0" id="myChart_1" class="left"
                         :style="{width: '37.5rem', height: '25rem'}"></div>
                </div>
            </div>
            <div class="dataBox" v-show="timeArr.length > 0">
                <div class="dataBox_3">
                    <div id="myChart_2" class="left" :style="{width: '37.5rem', height: '19.85rem'}"></div>
                </div>
            </div>
            <div class="dataBox">
                <div class="dataBox_4">
                    <ul>
                        <li v-for="(v, i) in data_saleTrend" :key="i">
                            <div class="tit_date" v-text="v.rpt_date"></div>
                            <div class="clear cont_value">
                                <div><span>北京：</span><span v-text="numFormat(v.bjSale)"></span></div>
                                <div><span>广东：</span><span v-text="numFormat(v.gdSale)"></span></div>
                                <div><span>天津：</span><span v-text="numFormat(v.tjSale)"></span></div>
                                <div><span>全国：</span><span v-text="numFormat(v.qgSale)"></span></div>
                            </div>
                        </li>
                        <!-- <li>
                            <div class="tit_date">2019-11-27</div>
                            <div class="clear cont_value">
                                <div><span>北京：</span><span>1,555,123</span></div>
                                <div><span>广东：</span><span>1,555,123</span></div>
                                <div><span>天津：</span><span>1,555,123</span></div>
                                <div><span>全国：</span><span>1,555,123</span></div>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    export default {
        name: 'daily',
        components: {},
        data () {
            return {
                token: this.getCookie('token') || '',
                hint: '',
                hint2: '',
                pickerVisible: this.formatDate(this.getYesterday),
                srchDate: this.formatDate(this.getYesterday),
                bjSale: '-',
                gdSale: '-',
                tjSale: '-',
                qgSale: '-',
                monthSale: '-',
                yearSale: '-',
                data_saleTrend: [],
                timeArr: [],
                data_bjArr: [],
                data_gdArr: [],
                data_tjArr: [],
                data_qgArr: [],
                timeArr2: [],
                dayArr: [],
                allArr: [],
                saleFlag:false,
            }
        },
        mounted () {
            this.getSaleByDay();
            this.getSaleTrend();
            this.getMatchUp();
        },
        watch: {
            'srchDate': {
                handler: function (newVal) {
                    this.getSaleByDay();
                    this.getSaleTrend();
                    this.getMatchUp();
                }
            }
        },
        methods: {
            openPicker () {
                this.$refs.picker.open();
            },
            // 格式化获取的时间
            formatDate (date) {
                const y = date.getFullYear()
                let m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                let d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                return y + '-' + m + '-' + d
            },
            //点击确定按钮之后
            handleConfirm () {
                // 输出格式化后的时间
                this.srchDate = this.formatDate(this.$refs.picker.value);
            },
            getSaleByDay () {
                this.bjSale = '-'
                this.gdSale = '-'
                this.tjSale = '-'
                this.qgSale = '-'
                this.monthSale = '-'
                this.yearSale = '-'
                this.$axios({
                    method: 'post',
                    url: 'web/GetSaleByDay',
                    data: {
                        token: this.token,
                        rpt_date: this.srchDate
                    },
                }).then((res) => {
                    let _data = res.data;
                    if (_data.code == 0) {
                        this.bjSale = _data.bjSale
                        this.gdSale = _data.gdSale
                        this.tjSale = _data.tjSale
                        this.qgSale = _data.qgSale
                        this.monthSale = _data.monthSale
                        this.yearSale = _data.yearSale
                        this.drawLine_0(_data.gdSale, _data.bjSale, _data.tjSale)
                    } else if (_data.code == -200) {
                        this.delCookie('token')
                    } else {
                        this.hint = _data.message
                    }
                }).catch((err) => {
                    this.hint = '网络异常'
                })
            },
            getSaleTrend () {
                this.timeArr = []
                this.data_bjArr = []
                this.data_gdArr = []
                this.data_tjArr = []
                this.data_qgArr = []
                this.hint = '数据加载中...'
                this.$axios({
                    method: 'post',
                    url: 'web/GetSaleTrend',
                    data: {
                        token: this.token,
                        rpt_date: this.srchDate
                    },
                }).then((res) => {
                    this.hint = ''
                    let _data = res.data
                    if (_data.code == 0) {
                        this.data_saleTrend = _data.dateList
                        for (let i in _data.dateList) {
                            if (_data.dateList[i].rpt_date != '合计（年度）') {
                                this.timeArr.push(_data.dateList[i].rpt_date)
                                this.data_bjArr.push(_data.dateList[i].bjSale)
                                this.data_gdArr.push(_data.dateList[i].gdSale)
                                this.data_tjArr.push(_data.dateList[i].tjSale)
                                this.data_qgArr.push(_data.dateList[i].qgSale)
                            }
                        }
                        if (this.timeArr.length == 0) {
                            this.hint = '无数据'
                        } else {
                            this.drawLine_1(this.timeArr, this.data_qgArr)
                            this.drawLine_2(this.timeArr, this.data_bjArr, this.data_gdArr, this.data_tjArr)
                        }
                    } else if (_data.code == -200) {
                        this.delCookie('token')
                    } else {
                        this.hint = _data.message
                    }
                    
                }).catch((err) => {
                    this.hint = '网络异常'
                })
            },
            getMatchUp () {
                this.timeArr2 = [];
                this.dayArr = [];
                this.allArr = [];
                this.hint2 = '数据加载中...';
                this.$axios({
                    method: 'post',
                    url: 'web/GetMatchUp',
                    data: {
                        token: this.token,
                        rpt_date: this.srchDate
                    },
                }).then((res) => {
                    this.hint2 = ''
                    let _data = res.data
                    if (_data.code == 0) {
                        this.saleFlag = _data.flag;
                        for (let i in _data.matchUpList) {
                            this.timeArr2.push(_data.matchUpList[i].rpt_date)
                            this.dayArr.push(_data.matchUpList[i].todayMatchCount)
                            this.allArr.push(_data.matchUpList[i].totalMatchCount)
                        }
                        if (this.timeArr.length == 0) {
                            this.hint = '无数据'
                        } else {
                            this.drawLine_3(this.timeArr2, this.allArr, this.dayArr);
                        }
                    } else if (_data.code == -200) {
                        this.delCookie('token')
                    } else {
                        this.hint2 = _data.message
                    }
                }).catch((err) => {
                    this.hint2 = '网络异常'
                })
            },
            drawLine_0 (data1, data2, data3) {
                let myChart = this.$echarts.init(document.getElementById('myChart_0'))
                myChart.setOption({
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    formatter: '{d}%',
                                    position: 'inside',
                                    color: '#000'
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: [
                                {value: data1, name: '广东'},
                                {value: data2, name: '北京'},
                                {value: data3, name: '天津'}
                            ]
                        }
                    ],
                    color: ['#54A64E', '#0968CE', '#F4B61B']
                })
            },
            drawLine_1 (timeArr, qgArr) {
                let myChart1 = this.$echarts.init(document.getElementById('myChart_1'))
                myChart1.setOption({
                    title: {
                        text: '北单全国销量走势图'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: timeArr
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: '全国',
                        data: qgArr,
                        type: 'line'
                    }]
                })
            },
            drawLine_2 (timeArr, bjArr, gdArr, tjArr) {
                let myChart2 = this.$echarts.init(document.getElementById('myChart_2'))
                myChart2.setOption({
                    title: {
                        text: '北单三地销量走势图'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['北京', '广东', '天津'],
                        x: 'right',
                        textStyle: {
                            fontSize: 22,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {}
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: timeArr
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: '北京',
                        type: 'line',
                        data: bjArr
                    }, {
                        name: '广东',
                        type: 'line',
                        data: gdArr
                    }, {
                        name: '天津',
                        type: 'line',
                        data: tjArr
                    }]
                })
            },
            drawLine_3 (timeArr, allArr, dayArr) {
                let myChart3 = this.$echarts.init(document.getElementById('myChart_3'));
                myChart3.setOption({
                    // title: {
                    //     text: '北单三地销量走势图'
                    // },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['总竞猜场次', '当日竞猜场次'],
                        x: 'right',
                        textStyle: {
                            fontSize: 22,
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {}
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: timeArr
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: '总竞猜场次',
                        type: 'line',
                        data: allArr
                    }, {
                        name: '当日竞猜场次',
                        type: 'line',
                        data: dayArr
                    }]
                })
            },

        }
    }
</script>
<style scoped>
    @import "~@/assets/css/daily.css";
</style>

