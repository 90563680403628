<template>
    <div class="echarts" :style="[{'width':salesCanvasSize.w,'height':salesCanvasSize.h}]">
        <div class="loading" v-show="loading">
            <div class="loading-wrap">
                <img src="../assets/imgs/loading.gif" alt="">
                <p>正在加载中...</p>
            </div>
        </div>
        <div class="loading" v-show="DataType">
            <div class="loading-wrap">
                <p>暂无数据！</p>
            </div>
        </div>
        <div class="salesMap" v-show="!DataType && !loading"
             :style="[{'width':salesCanvasSize.w,'height':salesCanvasSize.h}]"
             ref="salesMap"
             @click="aa($event)">
        </div>
    </div>
</template>
<script>
    import echarts from "echarts";

    export default {
        name: "salesLine",
        props: {
            option: {
                type: Object,
            },
            userJson: {
                type: String
            },
            salesCanvasSize: {
                type: Object
            },
            loading: {
                type: Boolean
            },
            DataType: {
                type: Boolean
            },
        },
        data () {
            return {
                chart: null,
            };
        },
        watch: {
            option: {
                handler () {
                    this.$nextTick(() => {
                        this.chinaConfigure();
                    })
                },
                deep: true
            }
        },
        mounted () {
            this.$nextTick(() => {
                this.chinaConfigure();
            })
        },
        beforeDestroy () {
            if (!this.chart) {
                return;
            }
            this.chart.dispose();
            this.chart = null;
        },
        methods: {
            aa: function (e) {
                if (e.target.id == 'mytooltip1') {
                    this.$emit('showSalesMask')
                }
                if (e.target.id == 'mytooltip2') {
                    this.$emit('showssSalesMask')
                }
            },
            chinaConfigure () {

                let myChart = echarts.init(this.$refs.salesMap); //这里是为了获得容器所在位置
                window.onresize = myChart.resize;
                myChart.setOption(this.option, true);
            }
        }
    }
</script>
<style scoped>
    .salesMap {
        margin: 0 auto;
        z-index: 1;
        position: relative;
    }

    .echarts {
        position: relative;
    }

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 2;
    }

    .loading-wrap {
        margin-top: 25%;
    }

    .loading-wrap img {
        width: 5rem;
        height: 5rem;
        margin: 0 auto;
    }

    .loading-wrap p {
        font-size: 1.4rem;
        margin-top: 1rem;
        text-align: center;
    }
</style>