<template>
    <div id="app">
        <Login :sessionToken="sessionToken" @session="getSession"></Login>
        <div v-if="sessionToken" class="content">
            <div id="nav">
                <!--<router-link to="/" exact>-->
                <!--<i></i>-->
                <!--<p>日报</p>-->
                <!--</router-link>-->
                <!-- <router-link to="/count">
                    <i></i>
                    <p>统计</p>
                </router-link> -->
                <a @click="userPermLink(1)" :class="linkIndex == 1 ? 'router-link-active' : ''">
                    <i></i>
                    <p>日报</p>
                </a>
                <a @click="userPermLink(3)" :class="linkIndex == 3 ? 'router-link-active' : ''">
                    <i></i>
                    <p>{{linkIndex != 3 ? '实时销量' : '刷新'}}</p>
                </a>
                <a @click="userPermLink(2)" :class="linkIndex == 2 ? 'router-link-active' : ''">
                    <i></i>
                    <p>统计</p>
                </a>
            </div>
            <router-view :isRefreshCount="isRefreshCount"/>
        </div>
    </div>
</template>
<style>
    @import "./assets/css/style.css";
</style>
<script>
    import Login from "@/views/login.vue";
    import {MessageBox} from 'mint-ui';

    export default {
        name: "App",
        components: {
            Login
        },
        mounted () {
            if (this.sessionToken) {
                if (window.location.href.indexOf('count') > -1) {
                    this.linkIndex = 2;
                } else {
                    if (window.location.href.indexOf('querySales') > -1) {
                        this.linkIndex = 3;
                    } else {
                        this.linkIndex = 1;
                    }
                }
            } else {
                this.linkIndex = 1;
            }
        },
        data () {
            return {
                sessionToken: !!this.getCookie('token'),
                hasSales: false,
                linkIndex: 1,
                isRefreshCount: 0,
            }
        },
        watch: {
            $route () {
                this.sessionToken = !!this.getCookie('token')
            },
            sessionToken () {
                let _getCookie = this.getCookie('token')
                if (!_getCookie) {
                    this.sessionToken = false
                }
            }
        },
        methods: {
            getSession (num) {
                this.sessionToken = num == 1;
            },
            userPermLink (type) {
                if (type == 1) {
                    this.isRefreshCount = 0;
                    this.linkIndex = 1;
                    this.$router.push({path: '/'});
                } else if (type == 2) {
                    this.isRefreshCount = 0;
                    let isPrem = this.getCookie('userPerm');
                    isPrem = isPrem.split(',');
                    let permObj = {1: 'sales', 2: 'match', 3: 'activity', 4: 'store'}
                    if (isPrem.indexOf('1') > -1 || isPrem.indexOf('2') > -1 || isPrem.indexOf('3') > -1 || isPrem.indexOf('4') > -1) {
                        this.linkIndex = 2;
                        this.$router.push({path: '/count/' + permObj[isPrem[0]]})
                    } else {
                        MessageBox('提示', '此用户暂无查看统计数据的权限')
                    }
                } else if (type == 3) {
                    let isPrem = this.getCookie('userPerm');
                    isPrem = isPrem.split(',');
                    if (isPrem.indexOf('10') > -1) {
                        if (window.location.href.indexOf('querySales') == -1) {
                            this.linkIndex = 3;
                            this.$router.push({path: '/querySales'})
                        } else {
                            this.isRefreshCount++
                        }
                    } else {
                        MessageBox('提示', '此用户暂无查看实时销量数据的权限')
                    }
                }

            }
        }
    };
</script>