import Vue from 'vue'
import App from './App.vue'
import router from './router'
import echarts from 'echarts'
import Axios from 'axios'
import {DatetimePicker} from 'mint-ui';
import 'mint-ui/lib/style.css'
import Qs from 'qs'
import './assets/css/my-mint.css';//全局修改mint-UI样式

Vue.component(DatetimePicker.name, DatetimePicker);
Vue.config.productionTip = false
Axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
Axios.defaults.transformRequest = [obj => Qs.stringify(obj)]
Vue.prototype.getConfigJson = function () {
    Axios.get('/config.json').then((result) => {
        Axios.defaults.baseURL = result.data.baseUrl;//设置成Vue的全局属性
        Vue.prototype.pageUrl = result.data.pageUrl;//设置成Vue的全局属性
        new Vue({
            router,
            render: h => h(App)
        }).$mount('#app')
    }).catch((error) => {
        alert('缺少配置文件')
    })
}
Vue.prototype.getConfigJson()//调用声明的全局方法

Vue.prototype.$axios = Axios

// Vue.prototype.refresh = function () {
//     window.location.reload()
// }

Vue.prototype.$echarts = echarts

Vue.prototype.getYesterday = new Date((new Date()).getTime() - 24 * 60 * 60 * 1000)
Vue.prototype.getCookie = function (name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)")
    if (arr = document.cookie.match(reg)) {
        return unescape(arr[2])
    } else {
        return ''
    }
}
Vue.prototype.delCookie = function (name) {
    var exp = new Date()
    exp.setTime(exp.getTime() - 60 * 60 * 1000);
    var cval = this.getCookie(name)
    if (cval != null) {
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString() + ";path=/"
    }
    window.location.reload()
}
Vue.prototype.numFormat = (num) => {
    if(num){
        let c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
        return c
    }else{
        return num
    }
   
}