import Vue from 'vue'
import VueRouter from 'vue-router'
import Daily from '@/views/Daily.vue'
import querySales from '@/views/querySales.vue'
Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return routerPush.call(this, location).catch(error => error)
}
const routes = [
    {
        path: '/Login',
        name: 'Login',
        component: () => import ('@/views/login.vue')
    },
    {
        path: '/salesLine',
        name: 'salesLine',
        component: () => import ('@/components/salesLine.vue')
    },
    {
        path: '/',
        name: 'daily',
        component: Daily
    },
    {
        path: '/querySales',
        name: 'querySales',
        component: querySales
    },
    {
        path: '/count',
        name: 'count',
        redirect: '/count/sales',
        // route level code-splitting
        // this generates a separate chunk (count.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "count" */ '@/views/Count.vue'),

        children: [{
            path: 'sales',
            meta: {
                title: '销量统计',
            },
            component: () => import('@/views/sales.vue'),
        }, {
            path: 'match',
            meta: {
                title: '赛事统计',
                activeName: 'count'
            },
            component: () => import('@/views/match.vue'),
        }, {
            path: 'activity',
            component: () => import('@/views/activity.vue'),
        }, {
            path: 'store',
            component: () => import('@/views/store.vue'),
        }]
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import ('@/views/login.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
