<template>
    <div v-if="!sessionToken" class="loginBox" :style="winH <= 1204?'': ('height:'+ winH +'px')">
        <div class="login_bg0">
            <div class="login_bg1">
                <div class="logo">
                    <img src="../assets/imgs/login_tit.png">
                </div>
                <div class="mainBox clearfix">
                    <div class="hint" v-text="hint">请输入用户名</div>
                    <div class="form">
                        <div class="group group1 clearfix">
                            <div class="icon icon0"></div>
                            <div class="inputBox">
                                <input type="tel" placeholder="手机号" v-model="phone" @keyup.enter="login()" maxlength="11">
                            </div>
                        </div>
                        <div class="group group1 group3 clearfix">
                            <div class="icon icon1"></div>
                            <div class="inputBox">
                                <input type="tel" placeholder="验证码" v-model="code" @keyup.enter="login()" maxlength="6">
                            </div>
                        </div>
                        <div class="group clearfix">
                            <!-- <div class="icon"></div>
                            <div class="inputBox input2">
                                <input type="text" placeholder="验证码" v-model="code">
                            </div> -->
                            <div class="btn btn_send" @click="getVerifyCode()" v-html="sendTxt"></div>

                        </div>
                        <div class="group group2 clearfix">
                            <div class="btn btn_login" @click="login()" v-html="loginTxt"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备06067490号-6</a>
        </footer>
    </div>
</template>

<script>
    import md5 from 'js-md5'

    export default {
        name: "Login",
        data () {
            return {
                winH: 1204,
                phone: '',
                code: '',
                hint: "",
                loginTxt: "登&nbsp;&nbsp;录",
                loginFlag: true,
                sendTxt: '发送验证码',
                codeFlag: true,
                time: 180,
                phoneReg: /^1(3|4|5|6|7|8|9)\d{9}$/
            };
        },
        props: {
            sessionToken: Boolean
        },
        mounted () {
        },
        methods: {
            login () {
                let codeReg = /^[0-9]{6}$/
                if (this.phone == "") {
                    this.hint = "手机号不能为空"
                    return
                }
                if(!this.phoneReg.test(this.phone)) {
                    this.hint = "手机号格式有误"
                    return
                }
                if (this.code == "") {
                    this.hint = "验证码不能为空"
                    return
                }
                if (!codeReg.test(this.code) || this.code.length > 20) {
                    this.hint = "验证码格式有误"
                    return
                }

                if (this.loginFlag) {
                    this.loginFlag = false
                } else {
                    return
                }
                this.hint = ''
                this.loginTxt = '登录中...'
                this.$axios({
                    method: 'post',
                    url: 'web/Login',
                    data: {
                        phone: this.phone,
                        validateCode: this.code
                        // user_pwd: md5(this.code)
                    },
                }).then((res) => {
                    this.loginFlag = true
                    this.loginTxt = '登&nbsp;&nbsp;录'
                    let _data = res.data
                    if (_data.code == 0) {
                        this.setCookie('phone', this.phone, 7, _data.token, _data.permStr)
                        // this.$store.commit('sendUserPerm',_data.permStr)
                        this.phone = ''
                        this.code = ''
                        this.$emit("session", 1)
                        this.$router.push("/")
                    } else {
                        this.hint = _data.message
                    }

                }).catch((err) => {
                    this.loginFlag = true
                    this.loginTxt = '登&nbsp;&nbsp;录'
                    this.hint = '网络异常'
                })
            },
            getVerifyCode() {
                if (this.phone == "") {
                    this.hint = "手机号不能为空"
                    return
                }
                if(!this.phoneReg.test(this.phone)) {
                    this.hint = "手机号格式有误"
                    return
                }
                if (this.codeFlag) {
                    this.codeFlag = false
                } else {
                    return
                }
                this.hint = ''
                this.sendTxt = '发送中'
                this.$axios({
                    method: 'post',
                    url: 'web/GetVerificationCode',
                    data: {
                        phone: this.phone,
                    },
                }).then((res) => {
                    this.sendTxt = '发送验证码'
                    let _data = res.data
                    if (_data.code == 0) {
                        this.countdown()
                    } else {
                        this.codeFlag = true
                        this.hint = _data.message
                    }

                }).catch((err) => {
                    this.codeFlag = true
                    this.sendTxt = '发送验证码'
                    this.hint = '网络异常'
                })
            },
            countdown() {
                if (this.time == 0) {
                    this.codeFlag = true
                    this.sendTxt = "发送验证码"
                    this.time = 180
                } else {
                    this.codeFlag = false
                    this.sendTxt = "重发(" + this.time + ")"
                    this.time--
                    setTimeout(() => {
                        this.countdown()
                    }, 1000)
                }
            },
            setCookie (cname, cvalue, exdays, token, permStr) {
                var d = new Date()
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
                var expires = "expires=" + d.toUTCString()
                var _token = "token=" + token
                var _userPerm = "userPerm=" + permStr
                // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
                document.cookie = _token + ";" + expires + ";path=/"
                document.cookie = _userPerm + ";" + expires + ";path=/"
            },
        },
        mounted () {
            this.winH = window.innerHeight
            // this.setCookie('phone', '', 0, '', '')
        }
    };
</script>

<style scoped>
    @import "./../assets/css/login.css";
</style>
