<template>
    <div class="module-title-wrap">
        <p class="module-title" v-text="title"></p>
    </div>
</template>

<script>
    export default {
        name: "module-title",
        props: ['title']
    }
</script>

<style scoped>
    .module-title-wrap {
        width: 100%;
        height: 4.5rem;
        background: rgba(239, 239, 239, 1) url("../assets/imgs/icon_bg_tit.png") no-repeat 0 center;
    }
    .module-title-wrap .module-title {
        padding:0 0 0 1rem;
        line-height: 4.5rem;
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 1);
    }
</style>